import { FunctionComponent } from 'react'

interface MainLayoutProps {
  children: JSX.Element
}

const MainLayout: FunctionComponent<MainLayoutProps> = (props): JSX.Element => {
  return <div className="container mx-auto mt-6">{props.children}</div>
}

export { MainLayout }
