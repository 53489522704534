import type { RefObject } from 'react'

interface VideoComponentProps {
  cameraVideoRef: RefObject<HTMLVideoElement>
  hidden: boolean
}

/** Displays webcam feed */
const VideoComponent = ({ cameraVideoRef, hidden }: VideoComponentProps): JSX.Element => {
  return (
    <>
      {/* Video player should only be shown when not hidden. */}
      {!hidden && (
        <div className="grid pt-8 mx-auto">
          <div className="mx-auto video-container video-wrapper">
            <video id="cameraVideo" className="video-full-size" ref={cameraVideoRef} autoPlay></video>
          </div>
        </div>
      )}
    </>
  )
}

export { VideoComponent }
