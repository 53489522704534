import type { RefObject } from 'react'

interface AudioChatComponentProps {
  audioOutputRef: RefObject<HTMLAudioElement>
}

const AudioChatComponent = ({ audioOutputRef }: AudioChatComponentProps): JSX.Element => {
  return (
    <>
      <audio id="audioOutput" ref={audioOutputRef} autoPlay></audio>
    </>
  )
}

export { AudioChatComponent }
