import { FunctionComponent, useEffect, useState, createRef } from 'react'

import { Manager, events } from '@meazure/copernicusjs'
import { MEDIA_SERVER_URL, VIDEO_SERVICE_URL } from '../config'
import { VideoComponent } from '../components/VideoComponent'
import { AudioChatComponent } from '../components/AudioChatComponent'
import { RecordingNotification } from '../components/RecordingNotificationComponent'
import {
  MicrophoneDevicesDropdownComponent,
  WebcamDevicesDropdownComponent,
} from '../components/DeviceDropdownComponent'
import { buildIceServers, buildPageUrl, buildUserId, newSecondCameraLink, useUnload } from '../utils'
import { VideoLayoutComponent } from '../components/VideoLayoutComponent'
import { v4 as uuid } from 'uuid'
import SecondCameraQrCode from '../components/SecondCameraQrCode'
import { RegionComponent } from '../components/RegionComponent'

/** Filter devices based on device type, and ignore "default" devices.
 *
 * User's should be explicit about inputs/outputs.
 */
const filterDevices = (devices: InputDeviceInfo[], kind: string): InputDeviceInfo[] => {
  return devices.filter((x) => x.kind === kind && x.label.toLowerCase() !== 'default')
}

/** Input device types */
enum InputDevices {
  CAMERA = 'videoinput',
  MICROPHONE = 'audioinput',
}

const getVideoPreview = (currentCameraDevice: string, videoPlayer: HTMLVideoElement) => {
  const videoConstraints = {
    width: { ideal: 1280 },
    height: { ideal: 720 },
    deviceId: { exact: currentCameraDevice },
  }

  navigator.mediaDevices
    .getUserMedia({ video: videoConstraints })
    .then((stream) => {
      if (videoPlayer !== null) {
        videoPlayer.srcObject = stream
        videoPlayer.play()
      }
    })
    .catch((err) => {
      console.error('error:', err)
    })
}

const TestTakerPage: FunctionComponent = (): JSX.Element => {
  // Session Details
  const [fulfillmentId, setFulfillmentId] = useState<string>('')
  const [userId, setUserId] = useState<string>('')
  const [role, setRole] = useState<string>('student')
  const [recordingAgreementStatus, setRecordingAgreementStatus] = useState<boolean>(false)
  const [recordingAgreementModalOpen, setRecordingAgreementModalOpen] = useState<boolean>(false)
  const [displaySecondCameraQrCode, setDisplaySecondCameraQrCode] = useState<boolean>(false)
  const [secondCameraLink, setSecondCameraLink] = useState<URL>(new URL(buildPageUrl('', '', '', '')))

  // Auth stuff...
  const [token, setToken] = useState<string>('')

  // Copernicus Instance
  const [copernicus, setCopernicus] = useState<Manager>(null)
  const [isConnectedToCopernicus, setIsConnectedToCopernicus] = useState<boolean>(false)

  // Reference for Camera Player
  const cameraVideoRef = createRef<HTMLVideoElement>()

  // Reference for Audio Chat
  const audioOutputRef = createRef<HTMLAudioElement>()

  // Media Device Information
  const [devices, setDevices] = useState<InputDeviceInfo[]>([])
  const [currentCameraDevice, setCurrentCameraDevice] = useState<string>('')
  const [currentMicrophoneDevice, setCurrentMicrophoneDevice] = useState<string>('')
  const [userMediaConsent, setUserMediaConsent] = useState<boolean>(false)
  const [screenSharingConsent, setScreenSharingConsent] = useState<boolean>(false)
  const [videoLayout, setVideoLayout] = useState<string>('combined')
  const [region, setRegion] = useState<string>('us-west-1')

  // Cleanup anything...
  useUnload((e) => {
    e.preventDefault()
    copernicus?.close()
  })

  const getCameraAndMicrophonePermissions = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        stream.getTracks().map((x) => x.stop()) // If you do not do this, then a stream will still be capturing data.
        return navigator.mediaDevices.enumerateDevices()
      })
      .then((devices) => {
        setDevices(devices)
        console.info('User has agreed to share camera and microphone.')
        setUserMediaConsent(true)
        setRecordingAgreementModalOpen(true)
      })
      .catch((e) => {
        console.error(e)
        console.info('User has denied sharing camera and microphone.')
      })
  }

  const getSessionParams = () => {
    const params = new URLSearchParams(window.location.search)
    setFulfillmentId(params.get('fulfillment_id') || '')
    setUserId(params.get('user_id') || '')
    setRole(params.get('role') || '')

    // Expect: http://localhost:3000/#token=foobar
    if (window.location.hash.includes('token')) {
      setToken(window.location.hash.split('token')[1].split('=')[1])
    }
  }

  const start = () => {
    connectToCopernicus()
    if (videoLayout === 'combinedWithSecondCamera') {
      generateAndSetSecondCameraLink()
    }
  }

  const stop = () => {
    disconnectFromCopernicus()
  }

  const generateAndSetSecondCameraLink = () => {
    const secondCameraNewLink = newSecondCameraLink(buildUserId(), fulfillmentId, region)
    setSecondCameraLink(secondCameraNewLink)

    console.debug('Second Camera url: ' + secondCameraNewLink)
  }

  // Connect to Manager
  const connectToCopernicus = () => {
    console.debug('Connecting to Copernicus...')
    const devices = {
      webcam: currentCameraDevice,
      audioInput: currentMicrophoneDevice,
    }

    const domElements = {
      webcam: cameraVideoRef.current,
      screen: null,
      audioOutput: null,
    }

    const iceServers = buildIceServers()

    try {
      const manager = new Manager({
        userID: userId,
        userUUID: uuid(),
        role,
        exam: fulfillmentId,
        domElements,
        mediaServerUrl: new URL(MEDIA_SERVER_URL),
        videoServiceUrl: new URL(VIDEO_SERVICE_URL),
        iceServers,
        devices,
        videoLayout: videoLayout,
        region: region,
      })

      setDisplaySecondCameraQrCode(videoLayout === 'combinedWithSecondCamera')

      manager.on('error', console.error)
      manager.on('connect', console.info)
      manager.on('disconnect', console.info)

      /* eslint-disable @typescript-eslint/no-explicit-any */
      manager.on('event', (x: any) => {
        if (x.source === 'media.screen') {
          switch (x.type) {
            case events.INPUT_PERMISSION_DENIED:
              setScreenSharingConsent(false)
              console.warn('User denied screen sharing permission')
              setTimeout(() => manager.stopScreenStream(), 0)
              setTimeout(() => manager.startScreenStream(), 0)
              break
            case events.AV_STREAM_STARTED:
              setScreenSharingConsent(true)
              console.info('User has selected a device.')
              break
          }
        }
        if (x.source === 'stream.chat') {
          switch (x.type) {
            case events.CALL_STARTED:
              console.info('TT automatically accepted incoming call request from Proctor with UserId: ', x.data.userId)
              manager.acceptIncomingAudioChatCall(x.data.userId)
              break
            case events.CALL_ENDED:
              console.warn('Proctor has ended audio call.')
              break
          }
        }
        if (x.source === 'secondCamera') {
          if (x.type === events.WEBRTC_STREAM_CONNECTION_STATE_CHANGED) {
            switch (x.data.connectionState) {
              case 'connected':
                console.info('Second Camera connected.')
                setDisplaySecondCameraQrCode(false)
                break
              case 'disconnected':
              case 'closed':
              case 'failed':
                console.warn('Second Camera disconnected.')
                setDisplaySecondCameraQrCode(true)
                break
              default:
                break
            }
          }
        }
      })

      const settings = {
        videoLayout: videoLayout,
        combinedStreamMaxBandwidth: 1000000,
        combinedStreamFrameRate: 10,
        secondCameraMaxBandwidth: 1500000,
      }
      manager
        .start(settings)
        .then(() => {
          setCopernicus(manager)
          setIsConnectedToCopernicus(true)
          manager.startWebRtcStatsReporter()
          console.info('[Copernicus] Connection established')
        })
        .catch((e) => {
          console.error(e)
          console.info('[Copernicus] Start failed')
        })
    } catch (e) {
      console.error(e)
      console.info('[Copernicus] Connection failed')
    }
  }

  const disconnectFromCopernicus = () => {
    copernicus.close()
    setCopernicus(null)
    setIsConnectedToCopernicus(false)
  }

  // Get device info on page load
  useEffect(() => {
    getSessionParams()
    getCameraAndMicrophonePermissions()
  }, [])

  // Set the current device to the first input received
  useEffect(() => {
    if (devices.length > 0) {
      setCurrentCameraDevice(filterDevices(devices, InputDevices.CAMERA)[0]?.deviceId || '')
      setCurrentMicrophoneDevice(filterDevices(devices, InputDevices.MICROPHONE)[0]?.deviceId || '')
    }
  }, [devices])

  // Logging
  useEffect(() => {
    console.debug(
      JSON.stringify({
        fulfillmentId,
        userId,
        token,
        currentMicrophoneDevice,
        currentCameraDevice,
        userMediaConsent,
        screenSharingConsent,
        recordingAgreementStatus,
        isConnectedToCopernicus,
      })
    )
  }, [
    fulfillmentId,
    userId,
    token,
    currentMicrophoneDevice,
    currentCameraDevice,
    userMediaConsent,
    screenSharingConsent,
    recordingAgreementStatus,
    isConnectedToCopernicus,
  ])

  // Display preview window after agreeing to be recorded
  useEffect(() => {
    if (recordingAgreementStatus && currentCameraDevice !== '' && !isConnectedToCopernicus) {
      getVideoPreview(currentCameraDevice, cameraVideoRef.current)
    }
  }, [recordingAgreementStatus, currentCameraDevice, cameraVideoRef])

  // Change Webcam Device while exam started
  useEffect(() => {
    if (isConnectedToCopernicus && currentCameraDevice !== '') {
      copernicus?.changeDevice('webcam', currentCameraDevice)
    }
  }, [isConnectedToCopernicus, currentCameraDevice, copernicus])

  // Change Microphone Device while exam started
  useEffect(() => {
    if (isConnectedToCopernicus && currentMicrophoneDevice !== '') {
      copernicus?.changeDevice('microphone', currentMicrophoneDevice)
      copernicus?.changeDevice('chat', currentMicrophoneDevice)
    }
  }, [isConnectedToCopernicus, currentMicrophoneDevice, copernicus])

  return (
    <>
      <RecordingNotification
        recordingAgreementModalOpen={recordingAgreementModalOpen}
        setRecordingAgreementModalOpen={setRecordingAgreementModalOpen}
        setRecordingAgreementStatus={setRecordingAgreementStatus}
      />

      <h1 id="session-header" className="text-3xl text-center font-bold">
        Welcome to your proctored session
      </h1>
      <div className="grid grid-cols-2 mt-12 mb-6">
        <div className="mx-auto">
          <h2 className="text-2xl font-bold">Camera</h2>
          <WebcamDevicesDropdownComponent
            inputDevices={filterDevices(devices, InputDevices.CAMERA)}
            setDeviceHandler={setCurrentCameraDevice}
          />
          <div style={isConnectedToCopernicus ? { pointerEvents: 'none', opacity: '0.4' } : {}}>
            <h2 className="text-2xl font-bold mt-6">Layout</h2>
            <VideoLayoutComponent setLayoutHandler={setVideoLayout} />
          </div>
        </div>
        <div className="mx-auto">
          <h2 className="text-2xl font-bold">Microphone</h2>
          <MicrophoneDevicesDropdownComponent
            inputDevices={filterDevices(devices, InputDevices.MICROPHONE)}
            setDeviceHandler={setCurrentMicrophoneDevice}
          />
          <div style={isConnectedToCopernicus ? { pointerEvents: 'none', opacity: '0.4' } : {}}>
            <h2 className="text-2xl font-bold mt-6">Region</h2>
            <RegionComponent setRegionHandler={setRegion} />
          </div>
        </div>
      </div>
      <div hidden={isConnectedToCopernicus || !recordingAgreementStatus}>
        <div className="mt-4 text-center">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={start}>
            Start
          </button>
        </div>
      </div>
      <div hidden={!isConnectedToCopernicus || !recordingAgreementStatus}>
        <div className="mt-4 text-center">
          <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={stop}>
            Stop
          </button>
        </div>
      </div>

      <SecondCameraQrCode secondCameraUrl={secondCameraLink} hidden={!displaySecondCameraQrCode} />

      {/* If user did not agree to recording agreement, do not display video player. */}
      <VideoComponent cameraVideoRef={cameraVideoRef} hidden={!recordingAgreementStatus} />

      <AudioChatComponent audioOutputRef={audioOutputRef} />
    </>
  )
}

export { TestTakerPage }
