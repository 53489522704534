import { FunctionComponent, useEffect, useState, createRef } from 'react'

import { Manager } from '@meazure/copernicusjs'
import { MEDIA_SERVER_URL, VIDEO_SERVICE_URL } from '../config'
import { VideoComponent } from '../components/VideoComponent'
import { RecordingNotification } from '../components/RecordingNotificationComponent'
import { WebcamDevicesDropdownComponent } from '../components/DeviceDropdownComponent'
import { buildIceServers, useUnload } from '../utils'

import { v4 as uuid } from 'uuid'

/** Filter devices based on device type, and ignore "default" devices.
 *
 * User's should be explicit about inputs/outputs.
 */
const filterDevices = (devices: InputDeviceInfo[], kind: string): InputDeviceInfo[] => {
  return devices.filter((x) => x.kind === kind && x.label.toLowerCase() !== 'default')
}

/** Input device types */
enum InputDevices {
  CAMERA = 'videoinput',
}

const getVideoPreview = (currentCameraDevice: string, videoPlayer: HTMLVideoElement) => {
  const videoConstraints = {
    width: { ideal: 1280 },
    height: { ideal: 720 },
    deviceId: { exact: currentCameraDevice },
  }

  navigator.mediaDevices
    .getUserMedia({ video: videoConstraints })
    .then((stream) => {
      if (videoPlayer !== null) {
        videoPlayer.srcObject = stream
        videoPlayer.play()
      }
    })
    .catch((err) => {
      console.error('error:', err)
    })
}

const SecondCameraPage: FunctionComponent = (): JSX.Element => {
  // Session Details
  const [fulfillmentId, setFulfillmentId] = useState<string>('')
  const [userId, setUserId] = useState<string>('')
  const [role, setRole] = useState<string>('secondCamera')
  const [recordingAgreementStatus, setRecordingAgreementStatus] = useState<boolean>(false)
  const [recordingAgreementModalOpen, setRecordingAgreementModalOpen] = useState<boolean>(false)

  // Auth stuff...
  const [token, setToken] = useState<string>('')

  // Copernicus Instance
  const [copernicus, setCopernicus] = useState<Manager>(null)
  const [isConnectedToCopernicus, setIsConnectedToCopernicus] = useState<boolean>(false)

  // Reference for Camera Player
  const secondCameraVideoRef = createRef<HTMLVideoElement>()

  // Media Device Information
  const [devices, setDevices] = useState<InputDeviceInfo[]>([])
  const [currentCameraDevice, setCurrentCameraDevice] = useState<string>('')
  const [userMediaConsent, setUserMediaConsent] = useState<boolean>(false)

  const [videoLayout, setVideoLayout] = useState<string>('secondCamera')
  const [region, setRegion] = useState<string>('')

  // Cleanup anything...
  useUnload((e) => {
    e.preventDefault()
    copernicus?.close()
  })

  const getCameraPermissions = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        stream.getTracks().map((x) => x.stop()) // If you do not do this, then a stream will still be capturing data.
        return navigator.mediaDevices.enumerateDevices()
      })
      .then((devices) => {
        setDevices(devices)
        console.info('User has agreed to share camera.')
        setUserMediaConsent(true)
        setRecordingAgreementModalOpen(true)
      })
      .catch((e) => {
        console.error(e)
        console.info('User has denied sharing camera.')
      })
  }

  const getSessionParams = () => {
    const params = new URLSearchParams(window.location.search)
    setFulfillmentId(params.get('fulfillment_id') || '')
    setUserId(params.get('user_id') || '')
    setRole(params.get('role') || '')
    setRegion(params.get('region') || '')

    // Expect: http://localhost:3000/#token=foobar
    if (window.location.hash.includes('token')) {
      setToken(window.location.hash.split('token')[1].split('=')[1])
    }
  }

  const connectToCopernicusCombinedWithSecondCamera = () => {
    connectToCopernicus()
  }

  // Connect to Manager
  const connectToCopernicus = () => {
    console.debug('Connecting to Copernicus...')
    const devices = {
      secondCamera: currentCameraDevice,
    }

    const domElements = {
      webcam: null,
      secondCamera: secondCameraVideoRef.current,
      screen: null,
      audioOutput: null,
    }

    const iceServers = buildIceServers()

    try {
      const manager = new Manager({
        userID: userId,
        userUUID: uuid(),
        role,
        exam: fulfillmentId,
        domElements,
        mediaServerUrl: new URL(MEDIA_SERVER_URL),
        videoServiceUrl: new URL(VIDEO_SERVICE_URL),
        iceServers,
        devices,
        videoLayout: videoLayout,
        region: region,
      })

      manager.on('error', console.error)
      manager.on('connect', console.info)
      manager.on('disconnect', console.info)
      manager.on('event', console.info)

      const settings = {
        videoLayout: videoLayout,
        combinedStreamMaxBandwidth: 1000000,
        combinedStreamFrameRate: 10,
        secondCameraMaxBandwidth: 1500000,
      }
      manager
        .start(settings)
        .then(() => {
          setCopernicus(manager)
          setIsConnectedToCopernicus(true)
          console.info('[Copernicus] Connection established')
        })
        .catch((e) => {
          console.error(e)
          console.info('[Copernicus] Start failed')
        })
    } catch (e) {
      console.error(e)
      console.info('[Copernicus] Connection failed')
    }
  }

  // Get device info on page load
  useEffect(() => {
    getSessionParams()
    getCameraPermissions()
    setVideoLayout('secondCamera')
  }, [])

  // Set the current device to the first input received
  useEffect(() => {
    if (devices.length > 0) {
      setCurrentCameraDevice(filterDevices(devices, InputDevices.CAMERA)[0]?.deviceId || '')
    }
  }, [devices])

  // Logging
  useEffect(() => {
    console.debug(
      JSON.stringify({
        fulfillmentId,
        userId,
        token,
        currentCameraDevice,
        userMediaConsent,
        recordingAgreementStatus,
        isConnectedToCopernicus,
      })
    )
  }, [
    fulfillmentId,
    userId,
    token,
    currentCameraDevice,
    userMediaConsent,
    recordingAgreementStatus,
    isConnectedToCopernicus,
  ])

  // Connect to copernicus after agreeing to be recorded
  useEffect(() => {
    if (recordingAgreementStatus && currentCameraDevice !== '') {
      connectToCopernicusCombinedWithSecondCamera()
    }
  }, [recordingAgreementStatus])

  // Display preview window after agreeing to be recorded
  useEffect(() => {
    if (recordingAgreementStatus && currentCameraDevice !== '') {
      getVideoPreview(currentCameraDevice, secondCameraVideoRef.current)
    }
  }, [recordingAgreementStatus, currentCameraDevice, secondCameraVideoRef])

  // Change Webcam Device while exam started
  useEffect(() => {
    if (isConnectedToCopernicus && currentCameraDevice !== '') {
      copernicus?.changeDevice('secondCamera', currentCameraDevice)
    }
  }, [isConnectedToCopernicus, currentCameraDevice, copernicus])

  return (
    <>
      <RecordingNotification
        recordingAgreementModalOpen={recordingAgreementModalOpen}
        setRecordingAgreementModalOpen={setRecordingAgreementModalOpen}
        setRecordingAgreementStatus={setRecordingAgreementStatus}
      />

      <div className="grid mt-2 mb-1">
        <div className="mx-auto">
          <h2 className="text-2xl font-bold">Camera</h2>
          <WebcamDevicesDropdownComponent
            inputDevices={filterDevices(devices, InputDevices.CAMERA)}
            setDeviceHandler={setCurrentCameraDevice}
          />
        </div>
      </div>

      {/* If user did not agree to recording agreement, do not display video player. */}
      <VideoComponent cameraVideoRef={secondCameraVideoRef} hidden={!recordingAgreementStatus} />
    </>
  )
}

export { SecondCameraPage }
