import { FunctionComponent } from 'react'
import { buildFulfillmentId, buildUserId, newProctorLink, newTestTakerLink } from '../utils'

const ErrorPage: FunctionComponent = (): JSX.Element => {
  /**
   * Returns a random integer between min (inclusive) and max (inclusive).
   * The value is no lower than min (or the next integer greater than min
   * if min isn't an integer) and no greater than max (or the next integer
   * lower than max if max isn't an integer).
   * Using Math.round() will give you a non-uniform distribution!
   */

  const fulfillmentId = buildFulfillmentId()

  const ttNewLink = newTestTakerLink(buildUserId(), fulfillmentId)
  const proctorNewLink = newProctorLink(buildUserId(), fulfillmentId)

  return (
    <>
      <h1 id="session-header" className="text-3xl text-center font-bold">
        Welcome to Direct Connect
      </h1>
      <br />
      <div className="mx-auto">
        <h2 className="text-2xl font-bold">Something went wrong and the URL for this page looks broken.</h2>
      </div>
      <br />
      <div className="mx-auto">
        <h2 className="text-2xl font-bold">Try this: </h2>
        <br />
        <h3 className="text-1xl font-bold">Test Taker:</h3>
        <a target="_blank" rel="noreferrer" href={ttNewLink.href}>
          {ttNewLink.href}
        </a>
        <br />
        <br />
        <h3 className="text-1xl font-bold">Proctor:</h3>
        <a target="_blank" rel="noreferrer" href={proctorNewLink.href}>
          {proctorNewLink.href}
        </a>
      </div>
    </>
  )
}

export { ErrorPage }
