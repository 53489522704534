import type { RefObject } from 'react'
import React, { useState } from 'react'
import { IconButton, Stack } from '@mui/material'
import { Fullscreen, Monitor, Person, PhotoCameraFront, TableRows, VolumeOff, VolumeUp } from '@mui/icons-material'

interface VideoComponentProps {
  videoRef: RefObject<HTMLVideoElement>
  hidden: boolean
}

/** Displays webcam feed */
const ProctorCombinedWithSecondCameraVideoComponent = ({ videoRef, hidden }: VideoComponentProps): JSX.Element => {
  const videoContainerRef = React.createRef()
  const playerRef = React.createRef()
  let fullScreenMode = false
  const views = {
    camera: 'camera',
    secondCamera: 'secondCamera',
    screen: 'screen',
    combinedWithSecondCamera: 'combinedWithSecondCamera',
  }
  let currentView = views.combinedWithSecondCamera

  const [isAudioMute, setAudiomute] = useState<boolean>(false)

  const updateStyles = (view: string) => {
    if (videoContainerRef === null || videoRef === null || playerRef === null) {
      return
    }

    // Manipulates necessary classes
    if (!fullScreenMode) {
      playerRef.current.classList.add('view-zoom-second-camera')
      videoRef.current.classList.remove('view-fullscreen')
      videoContainerRef.current.classList.remove('view-fullscreen')
      videoContainerRef.current.classList.add('view-zoom-second-camera')
    } else {
      videoRef.current.classList.remove('view-fullscreen-combined')
      videoRef.current.classList.add('view-fullscreen')
      videoContainerRef.current.classList.remove('view-zoom-second-camera')
      videoContainerRef.current.classList.add('view-fullscreen')
    }

    currentView = view

    switch (view) {
      case 'camera':
        videoContainerRef.current.scrollTop = 0
        break
      case 'secondCamera':
        if (!fullScreenMode) {
          videoContainerRef.current.scrollTop = 240
        } else {
          videoContainerRef.current.scrollTop = 1080
        }
        break
      case 'screen':
        if (!fullScreenMode) {
          videoContainerRef.current.scrollTop = 480
        } else {
          videoContainerRef.current.scrollTop = 2160
        }
        break
      case 'combinedWithSecondCamera':
        if (!fullScreenMode) {
          playerRef.current.classList.remove('view-zoom-second-camera')
          videoRef.current.classList.remove('view-fullscreen-combined')
          videoContainerRef.current.classList.remove('view-zoom-second-camera')
        } else {
          playerRef.current.classList.remove('view-zoom-second-camera')
          videoRef.current.classList.remove('view-fullscreen')
          videoRef.current.classList.add('view-fullscreen-combined')
          videoContainerRef.current.scrollTop = 0
        }
        break
    }
  }

  const changeCameraZoom = () => {
    updateStyles(views.camera)
  }

  const changeSecondCameraZoom = () => {
    updateStyles(views.secondCamera)
  }
  const changeScreenZoom = () => {
    updateStyles(views.screen)
  }
  const normalZoom = () => {
    updateStyles(views.combinedWithSecondCamera)
  }

  function enterFS(element) {
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen()
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen()
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen()
    }
  }

  function exitFS() {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    }
  }

  const toggleFullScreen = () => {
    if (!fullScreenMode) {
      fullScreenMode = true
      enterFS(playerRef.current)
    } else {
      fullScreenMode = false
      exitFS()
    }
    updateStyles(currentView)
  }

  const toggleAudioMute = () => {
    videoRef.current.muted = !videoRef.current.muted
    setAudiomute(!isAudioMute)
  }

  const renderNormalZoomButton = () => {
    return (
      <IconButton onClick={normalZoom}>
        <TableRows sx={{ fontSize: '2rem', color: 'white' }} />
      </IconButton>
    )
  }
  const renderCameraZoomButton = () => {
    return (
      <IconButton onClick={changeCameraZoom}>
        <Person sx={{ fontSize: '2rem', color: 'white' }} />
      </IconButton>
    )
  }

  const renderSecondCameraZoomButton = () => {
    return (
      <IconButton onClick={changeSecondCameraZoom}>
        <PhotoCameraFront sx={{ fontSize: '2rem', color: 'white' }} />
      </IconButton>
    )
  }

  const renderScreenZoomButton = () => {
    return (
      <IconButton onClick={changeScreenZoom}>
        <Monitor sx={{ fontSize: '2rem', color: 'white' }} />
      </IconButton>
    )
  }

  const renderFullScreenButton = () => {
    return (
      <IconButton onClick={toggleFullScreen}>
        <Fullscreen sx={{ fontSize: '2rem', color: 'white' }} />
      </IconButton>
    )
  }

  const renderAudioMuteButton = () => {
    return (
      <IconButton onClick={toggleAudioMute}>
        {isAudioMute ? (
          <VolumeOff sx={{ fontSize: '2rem', color: 'white' }} />
        ) : (
          <VolumeUp sx={{ fontSize: '2rem', color: 'white' }} />
        )}
      </IconButton>
    )
  }

  const renderAudioVolumeProp = () => {
    return <div>{renderAudioMuteButton()}</div>
  }

  return (
    <>
      <div className="player" ref={playerRef} style={{ display: hidden ? 'none' : '' }}>
        <div className="videoContainer" ref={videoContainerRef}>
          <div className="player-controls">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="row" alignItems="center" spacing={3}>
                {renderAudioVolumeProp()}
              </Stack>
              <Stack direction="row" alignItems="center" spacing={3}>
                {renderNormalZoomButton()}
                {renderCameraZoomButton()}
                {renderSecondCameraZoomButton()}
                {renderScreenZoomButton()}
                {renderFullScreenButton()}
              </Stack>
            </Stack>
          </div>
          <video
            className="proctorCombinedWithSecondCameraVideo"
            id="CombinedWithSecondCameraVideo"
            ref={videoRef}
            autoPlay
          />
        </div>
      </div>
    </>
  )
}

export { ProctorCombinedWithSecondCameraVideoComponent }
