import type { RefObject } from 'react'

interface VideoComponentProps {
  videoRef: RefObject<HTMLVideoElement>
  hidden: boolean
}

/** Displays webcam feed */
const ProctorVideoComponent = ({ videoRef, hidden }: VideoComponentProps): JSX.Element => {
  return (
    <>
      <div>
        <video
          id="proctorVideo"
          controls={true}
          style={{
            display: hidden ? 'none' : '',
            width: '100%',
          }}
          ref={videoRef}
          autoPlay
        ></video>
      </div>
    </>
  )
}

export { ProctorVideoComponent }
