import { Dialog, Transition } from '@headlessui/react'
import { Dispatch, Fragment, SetStateAction } from 'react'

const logRecordingStatus = (recordingAgreementStatus: boolean): void => {
  console.info(`User agreed to be recorded: ${recordingAgreementStatus}`)
}

interface RecordingNotificationProps {
  recordingAgreementModalOpen: boolean
  setRecordingAgreementStatus: Dispatch<SetStateAction<boolean>>
  setRecordingAgreementModalOpen: Dispatch<SetStateAction<boolean>>
}

const RecordingNotification = ({
  setRecordingAgreementStatus,
  recordingAgreementModalOpen,
  setRecordingAgreementModalOpen,
}: RecordingNotificationProps): JSX.Element => {
  const acceptRecordingAgreement = () => {
    logRecordingStatus(true)
    setRecordingAgreementStatus(true)
    setRecordingAgreementModalOpen(false)
  }

  const denyRecordingAgreement = () => {
    logRecordingStatus(false)
    setRecordingAgreementStatus(false)
    setRecordingAgreementModalOpen(true)
  }

  return (
    <>
      <Transition appear show={recordingAgreementModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={denyRecordingAgreement}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div id="recordingAgreementModal" className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Recording Agreement
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      By clicking &quot;Agree&quot;, you agree to be immediately recorded.
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      id="recordingAgreeButton"
                      type="button"
                      onClick={acceptRecordingAgreement}
                      className="inline-flex bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border rounded float-right"
                    >
                      Agree
                    </button>
                    <button
                      id="recordingDenyButton"
                      type="button"
                      onClick={denyRecordingAgreement}
                      className="inline-flex bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 ml-2 border rounded float-right"
                    >
                      Deny
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export { RecordingNotification }
