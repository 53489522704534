import { QRCodeCanvas } from 'qrcode.react'

interface SecondCameraQrCodeComponentProps {
  secondCameraUrl: URL
  hidden: boolean
}

const SecondCameraQrCode = ({ secondCameraUrl, hidden }: SecondCameraQrCodeComponentProps) => {
  const qrcode = (
    <QRCodeCanvas id="qrCode" value={secondCameraUrl.toString()} size={160} bgColor={'#FFFFFF'} level={'H'} />
  )

  return (
    <>
      {/* Qr Code should only be shown when TestTaker has selected to 'Connect Combined with Second Camera' and the Second Camera is not connected. */}
      {!hidden && (
        <div className="grid">
          <div className="mx-auto mt-1"> Scan the Qr Code with your mobile phone to connect the Second Camera </div>
          <div className="mx-auto mt-1">
            <a href={secondCameraUrl.toString()} target="_blank" rel="noreferrer noopener">
              {qrcode}
            </a>
          </div>
        </div>
      )}
    </>
  )
}

export default SecondCameraQrCode
