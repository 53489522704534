/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import { TURN_STUN_PASSWORD, TURN_STUN_SERVER_URL, TURN_STUN_USERNAME } from './config'

/** Helper to safely cleanup any resources before the user leaves the window */
const useUnload = (fn: (e: any) => void) => {
  const cb = useRef(fn)

  useEffect(() => {
    const onUnload = cb.current
    window.addEventListener('beforeunload', onUnload)
    return () => {
      window.removeEventListener('beforeunload', onUnload)
    }
  }, [cb])
}

const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const buildUserId = () => {
  return getRandomInt(100, 9999).toString()
}

// Creates new Fulfillment ID
const buildFulfillmentId = () => {
  return uuid()
}

const buildPageUrl = (userId: string, fulfillmentId: string, role: string, token: string, region: string) => {
  const pageData = {
    fulfillment_id: fulfillmentId,
    user_id: userId,
    role: role,
    token: token,
    region: region,
  }

  const searchParams = new URLSearchParams(pageData)
  const newLink = new URL(window.location.protocol + '//' + window.location.host + '/?' + searchParams)

  return newLink
}

const newTestTakerLink = (userId: string, fulfillmentId: string) => {
  return buildPageUrl(userId, fulfillmentId, 'student', 'foo', '')
}

const newProctorLink = (userId: string, fulfillmentId: string) => {
  return buildPageUrl(userId, fulfillmentId, 'proctor', 'foo', '')
}

const newSecondCameraLink = (userId: string, fulfillmentId: string, region: string) => {
  return buildPageUrl(userId, fulfillmentId, 'secondCamera', 'foo', region)
}

const buildIceServers = () => {
  if (TURN_STUN_SERVER_URL) {
    return [
      {
        url: `turn:${TURN_STUN_SERVER_URL}?transport=udp`,
        username: TURN_STUN_USERNAME,
        credential: TURN_STUN_PASSWORD,
      },
      {
        url: `turn:${TURN_STUN_SERVER_URL}?transport=tcp`,
        username: TURN_STUN_USERNAME,
        credential: TURN_STUN_PASSWORD,
      },
      {
        url: `turns:${TURN_STUN_SERVER_URL}?transport=tcp`,
        username: TURN_STUN_USERNAME,
        credential: TURN_STUN_PASSWORD,
      },
    ]
  } else {
    return null
  }
}

export {
  useUnload,
  getRandomInt,
  buildUserId,
  buildFulfillmentId,
  newTestTakerLink,
  newProctorLink,
  newSecondCameraLink,
  buildPageUrl,
  buildIceServers,
}
