import { FunctionComponent } from 'react'
import './App.css'
import { MainLayout } from './layouts/main'
import { TestTakerPage } from './pages/TestTakerSession'
import { SecondCameraPage } from './pages/SecondCameraSession'
import { ProctorPage } from './pages/ProctorSession'
import { ErrorPage } from './pages/ErrorSession'

const App: FunctionComponent = (): JSX.Element => {
  const params = new URLSearchParams(window.location.search)
  const role = params.get('role')
  if (role == 'student') {
    return (
      <MainLayout>
        <TestTakerPage />
      </MainLayout>
    )
  }

  if (role == 'proctor') {
    return (
      <MainLayout>
        <ProctorPage />
      </MainLayout>
    )
  }

  if (role == 'secondCamera') {
    return (
      <MainLayout>
        <SecondCameraPage />
      </MainLayout>
    )
  }

  return (
    <MainLayout>
      <ErrorPage />
    </MainLayout>
  )
}

export default App
