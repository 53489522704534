/**
Application level config values

Values are replaced at build time. Reads either from environment or .env
*/

/** Determines the build environment */
export const ENVIRONMENT: string = process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV

export const TURN_STUN_SERVER_URL: string = process.env.REACT_APP_TURN_STUN_SERVER_URL || ''
export const TURN_STUN_USERNAME: string = process.env.REACT_APP_TURN_STUN_USERNAME || ''
export const TURN_STUN_PASSWORD: string = process.env.REACT_APP_TURN_STUN_PASSWORD || ''

export const MEDIA_SERVER_URL: string = process.env.REACT_APP_MEDIA_SERVER_URL || ''
export const VIDEO_SERVICE_URL: string = process.env.REACT_APP_VIDEO_SERVICE_URL || ''

console.debug(`[Direct Connect] - Build environment: ${ENVIRONMENT}`)
